'use strict';

var empty = function() {};

const Day = {
  getFullDay: function (date, callback) {
    return this.getDay(date, { fullday: true }, callback);
  },

  getSmallDay: function (date) {
    return this.getDay(date, { smallday: true });
  },

  getDay: function (date, data, callback) {
    return $.ajax({
      url: '/' + [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('/'),
      data: data,
      type: 'GET',
      dataType: 'html',
      success: callback,
    });
  },

  preLoadAdjacent: function (date) {
    date = new Date(date);

    date.setDate(date.getDate() - 1);
    this.getFullDay(date, empty);
    this.getSmallDay(date, empty);

    date.setDate(date.getDate() + 1);
    this.getFullDay(date, empty);
    this.getSmallDay(date, empty);

    date.setDate(date.getDate() + 1);
    this.getFullDay(date, empty);
    this.getSmallDay(date, empty);
  },
};

export default Day;
