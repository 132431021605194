import App from './app';
import throttle from './throttle';

// Load more smalldays
jQuery(function ($) {
  var before = $('.before'),
      after = $('.after'),
      w = $(window);

  needMoreDays();
  w.on('resize', throttle(needMoreDays, 1000));

  function needMoreDays () {
    if(before.offset().left > 0) {
      moreDays(function () {
        setTimeout(needMoreDays, 1000);
      });
    }
  }

  function moreDays (callback) {
    var earliestDay = before.find('.day').first(),
        latestDay = after.find('.day').last(),
        earliestDate = earliestDay.attr('href'),
        latestDate = latestDay.attr('href'),
        newEarlyDate = new Date(earliestDate.split('/').splice(1).join('-')),
        newLateDate = new Date(latestDate.split('/').splice(1).join('-'));

    newEarlyDate.setDate(newEarlyDate.getDate() - 1);
    newLateDate.setDate(newLateDate.getDate() + 1);

    $.when(
      App.Day.getSmallDay(newEarlyDate),
      App.Day.getSmallDay(newLateDate)
    ).then(function (first, last) {
      insertDay(first[0], earliestDay, true);
      insertDay(last[0], latestDay);
      callback();
    });
  }

  function insertDay (html, target, before) {
    var element = $(' ' + html);

    element
      .addClass('fadein');

    if(before) {
      target.before(element);
    }
    else {
      target.after(element);
    }

    App.days = $('.before .day, .after .day');

    setTimeout(function () {
      element.removeClass('fadein');
    }, 16.67);
  }
});
