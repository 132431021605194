import Day from './Day';

const app = {
  Day: Day,
  days: undefined,
  isLarge: function () {
    return app.activeMediaquery() === 'large';
  },
  isSmall: function () {
    return app.activeMediaquery() === 'small';
  },
  activeMediaquery: function () {
    return window.getComputedStyle(document.getElementById('mediaquery'), ':after').getPropertyValue('content');
  },
};

jQuery(function ($) {
  // Make the narrow columns have full height
  var w = $(window),
      article = $('.today'),
      lastHeight = w.height(),
      wrap = $('.wrap');

  app.days = $('.before .day, .after .day');

  function dayHeight () {
    $('.before, .after').height(Math.max(article.outerHeight(), w.height() - parseInt($(wrap).css('margin-bottom'), 10)));
  }

  dayHeight();

  w.on('resize', function () {
    var height = w.height();

    if(lastHeight !== height) {
      lastHeight = height;
      dayHeight();
    }
  });
});

jQuery(function ($) {
  // In desktop, hide header/footer when clicking anywhere
  var w = $(window),
      headToggle = $('#menu-toggle-checkbox');

  w.on('mouseup touchend', function (e) {
    if(app.isLarge()) {
      if(!$(e.target).closest('.head').length) {
        headToggle.prop('checked', false);
      }
    }
  });

  // And hide on ESC
  w.on('keydown', function (e) {
    var ESC = 27;

    if(e.which === ESC && app.isLarge()) {
      headToggle.prop('checked', false);
    }
  });
});

jQuery(function ($) {
  /**
   * For devices we want linked religion-icons to show a tooltip when tapped, so
   * that the user can see what the icon means. If the user taps the tooltip
   * (or taps the link again), the link should redirect like a normal link.
   * If the users touches anywhere else, the tooltip should disappear.
   */
  
  var links = $('a.icon'),
      w = $(document);

  w.on('touchstart', links.selector, function (e) {
    var link = $(this);

    // First tap
    if(!link.hasClass('tooltip') && app.isSmall()) {
      link.addClass('tooltip');
      e.preventDefault();
    }

    e.stopPropagation();
  });

  w.on('touchstart', function () {
    $(links.selector).removeClass('tooltip');
  });
});

export default app;
