'use strict';

$(function() {
    require('./apps/app.js');

    require('./apps/Day.js');

    require('./apps/dayloader.js');

    require('./apps/fullLoader.js');

    require('./apps/throttle.js');
});
