import App from './app';

/**
 * Load full posts. Currently only for mobile view with swipe
 */

jQuery(function ($) {
  var hasEverPushed = false,
      currentDate = new Date($('.today').attr('data-date')),
      oldDate = currentDate,
      w = $(window),
      d = $(document),
      direction,
      start = { x: undefined, y: undefined },
      arrowNav = $('#arrow-nav'),
      leftArrow = $('#arrow-nav-prev'),
      rightArrow = $('#arrow-nav-next'),
      touchingArrow = false,
      spinner = $('#spinner'),
      before = $('.before'),
      after = $('.after');


  // Pre-load adjacent days
  App.Day.preLoadAdjacent(currentDate);

  d.on('touchend', shouldLoadSmall);

  d.on('touchstart', function (e) {
    var touches = e.originalEvent.changedTouches;

    // Only react on touch with one finger
    if(touches.length === 1) {
      start.x = touches[0].pageX;
      start.y = touches[0].pageY;
    }
  });

  d.on('touchmove', function (e) {
    var a, b, c, x, y, touch;

    // Abort if the touch started on arrow
    if(touchingArrow) {
      return;
    }

    // No need to do anything if there's no start
    // No need to do anything if we've already decided on a direction
    if(start.x && !direction) {
      touch = e.originalEvent.changedTouches[0];
      x = touch.pageX;
      y = touch.pageY;
      a = x - start.x;
      b = y - start.y;
      c = a + b; // Cheap substitute for pythagoras theorem

      // Don't do anything unless the finger moved some distance from the start
      if(Math.abs(c) > 20) {
        // Prevent scrolling
        e.preventDefault();

        // Figure out the direction with a very basic approximation
        if(a > 0) {
          // More right than left
          if(Math.abs(b) < a) {
            // More right than up or down
            direction = 'right';
          }
        }
        else {
          if(Math.abs(b) < -a) {
            // More left than up or down
            direction = 'left';
          }
        }

        // Load post immediately
        shouldLoadSmall();
      }
    }
  });


  /**
   * When tapping on nav arrows
   * Opposite because when one swipes left one wants the next day,
   * but when one taps left one wants the previous day
   */

  leftArrow.on('touchend', function (e) {
    direction = 'right';
    shouldLoadSmall();
    e.stopPropagation();
  });

  rightArrow.on('touchend', function (e) {
    direction = 'left';
    shouldLoadSmall();
    e.stopPropagation();
  });

  // Prevent double-scroll by disabling swipe when touch starts on an arrow
  leftArrow.add(rightArrow).on('touchstart', function (e) {
    if(e.originalEvent.changedTouches.length === 1) {
      touchingArrow = true;
    }
  });

  leftArrow.add(rightArrow).on('touchend', function () {
    touchingArrow = false;
  });


  /**
   * When clicking a smallDay in desktop, slide it in!
   */

  d.on('mouseup touchend', '.day', function (e) {
    var day = $(this),
        parent = day.parent();

    direction = parent.hasClass('before') ? 'right' : 'left';
    oldDate = currentDate;
    currentDate = makeDateFromPath(day.attr('href'));

    slide();
    updateSmallDays(day);

    // Reset vars
    oldDate = currentDate;
    direction = undefined;
    e.preventDefault();
  })
  .on('click', '.day', function (e) {
    e.preventDefault();
  });

  // Handle popstate
  w.on('popstate', function () {
    var path = document.location.pathname,
        newDate = makeDateFromPath(path),
        clicked;

    if(!path.slice(1) && !hasEverPushed) {
      return;
    }

    // If pathname is "^/{0,1}$", it's today
    if(isNaN(newDate.valueOf())) {
      newDate = daySpecificDate();
      path = makePathFromDate(newDate);
    }

    if(+newDate !== +currentDate) {
      oldDate = currentDate;
      currentDate = newDate;

      clicked = before.add(after).find('[href="' + path + '"]');

      if(clicked.length) {
        direction = clicked.parent().hasClass('before') ? 'right' : 'left';
        slide();
        updateSmallDays(clicked);
      }
      else {
        // Force update if we don't know where to slide
        window.location = path;
      }
    }

    hasEverPushed = true;
  });


  function shouldLoadSmall () {
    // Only react on small view
    if(w.width() > 860) {
      return;
    }

    if(direction) {
      if(direction === 'right') {
        currentDate.setDate(currentDate.getDate() - 1);
      }
      else if(direction === 'left') {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      slide();
    }

    // Clear start coords and direction
    start.x = undefined;
    start.y = undefined;
    direction = undefined;
  }

  function slide () {
    // Hide arrowNav
    arrowNav.addClass('hide');
    spinner.addClass('spin');

    moveOut(direction === 'right' ? 'left' : 'right');
    App.Day.getFullDay(currentDate, swipeToDay(direction, function () {
      pushState();

      // Show arrowNav
      arrowNav.removeClass('hide');

      // Pre-load adjacent days
      App.Day.preLoadAdjacent(currentDate);
    }));
  }

  function updateSmallDays (clicked) {
    var from, to, popFrom, appendFrom, appendTo,
        moved, movedCount, loadMoreDate, reverseFrom, reverseTo, m;

    if(direction === 'right') {
      from = before;
      to = after;
      popFrom = 'nextAll';
      appendFrom = 'prependTo';
      appendTo = 'prependTo';
      loadMoreDate = makeDateFromPath(before.find('.day').first().attr('href'));
      reverseFrom = true;
      reverseTo = false;
      m = -1;
    }
    else {
      from = after;
      to = before;
      popFrom = 'prevAll';
      appendFrom = 'appendTo';
      appendTo = 'appendTo';
      loadMoreDate = makeDateFromPath(after.find('.day').last().attr('href'));
      reverseFrom = false;
      reverseTo = true;
      m = 1;
    }

    // Get the ones closer from clicked
    moved = clicked[popFrom]();
    movedCount = moved.length;

    // Fetch new smallDays
    $.when.apply($,
      [App.Day.getSmallDay(oldDate)]
      .concat($.map(new Array(movedCount + 1), function (_, i) {
        var date = makeDateFromPath(makePathFromDate(loadMoreDate));
        //daySpecificDate();

        date.setDate(loadMoreDate.getDate() + (i+1)*m);
        return App.Day.getSmallDay(date);
      }))
    )
    .then(function () {
      var newDays = [].slice.call(arguments),
          oldDay = $(newDays.shift()[0]),
          removeCount;

      newDays = $($.map(newDays, function (html) {
        return $(html[0])[0];
      }));

      // When putting things in before, they need to be reversed
      if(reverseFrom) {
        newDays = $(newDays.get().reverse());
      }

      if(reverseTo) {
        moved = $(moved.get().reverse());
      }

      // The old date should be put closest in `to`
      oldDay[appendTo](to);

      // All closer to clicked should be put closest in `to`
      moved[appendTo](to);

      // As many as were removed _plus one_ should be added furthest away from clicked
      newDays[appendFrom](from);

      // As many as all closer to clicked _plus one_ should be removed furthest away in `to`
      removeCount = (movedCount+1) * m;

      if(removeCount < 0) {
        to.find('.day').slice(removeCount).remove();
      }
      else {
        to.find('.day').slice(0, removeCount).remove();
      }

      // Clicked is safe to remove
      clicked.remove();
    });
  }

  function moveOut (direction) {
    var oldElement = $('.today');

    oldElement
      .addClass('swiping')
      .addClass(direction);
  }

  function swipeToDay (direction, callback) {
    return function (html) {
      var newElement = $(html),
          oldElement = $('.today');

      // Hide spinner as soon as possible
      spinner.removeClass('spin');

      newElement
        .addClass('swiping')
        .addClass(direction)
        .insertAfter(oldElement.last());

      oldElement
        .addClass('begone');

      setTimeout(function () {
        newElement.removeClass(direction);

        setTimeout(function () {
          oldElement.remove();
          newElement.removeClass('swiping');
          callback();
        }, 400);
      }, 10);
    };
  }

  function makePathFromDate (date) {
    return '/' + [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).substr(-2),
      ('0' + date.getDate()).substr(-2)]
      .join('/');
  }

  function makeDateFromPath (path) {
    return new Date(path.slice(1).replace(/\//g, '-'));
  }

  function daySpecificDate () {
    var date = new Date();

    return makeDateFromPath(makePathFromDate(date));
  }

  function pushState () {
    var newPath;

    if(window.history && window.history.pushState) {
      newPath = makePathFromDate(currentDate);

      if(newPath !== document.location.pathname) {
        hasEverPushed = true;
        window.history.pushState(null, document.title, newPath);
      }
    }
  }
});
